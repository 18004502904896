.wizard {
  margin-top: calc(var(--header-height));
  margin-bottom: var(--footer-height) !important;
}

.wizard-secondary {
  margin-top: calc(var(--header-height));
}

a {
  text-decoration: none;
}
