.reports-link-font-size {
  font-size: 1.25em;
}
.cursor-pointer {
  cursor: pointer;
}
.digital-forestry-icon-size {
  height: 1.5em;
}
.customer-name:hover {
  opacity: 0.7;
}
