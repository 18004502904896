.contact-card {
  padding: 25px;
  border: 1px solid var(--gray-40);
  border-radius: 4px;
  min-width: 335px;
  max-width: 335px;
  position: relative;

  &-row {
    &-title {
      display: block;
      padding-right: 10px;
      flex-basis: 35%;
      overflow: hidden;
    }

    &-text {
      display: block;
      flex-basis: 65%;
      font-weight: 500;
      padding-left: 4px;
      overflow: hidden;
    }
  }

  .edit-icon {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 18px;
  }
}
