.lineContainer {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr repeat(2, 2fr) 1fr;
}

.lineContainerHeader {
  display: flex;
  justify-content: flex-end;
}

.volumeAnalysis td,
.volumeAnalysis th {
  font-size: 1rem;
  padding: 0.25rem;
}

.volumeAnalysis button {
  float: right;
  font-size: 1rem;
  padding: 0.125rem 1rem;
  margin-right: 2rem;
}

.volumeAnalysisContainer {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr repeat(2, 3fr) 1fr;
}

.periodSpan {
  font-weight: 600;
  color: #697789; /* TODO: Import */
}

.warningsWrapper {
  padding-bottom: 1em;
  width: 20em;
}

.line {
  border-bottom: 1px solid #6977893f;
  font-weight: 600;
  color: #697789;
  font-size: 1rem;
  padding: 0.25rem;
  text-align: right;
}

.lineText {
  border-bottom: 1px solid #6977893f;
  font-weight: 600;
  color: #697789;
  font-size: 1rem;
  padding: 0.25rem;
}

.lineWB {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 0.5fr;
  border-bottom: 1px solid #6977893f;
  font-weight: 600;
  color: #697789;
  font-size: 1rem;
  padding: 0.25rem;
  padding-right: 2rem;
}

.expandedLine {
  border-bottom: 1px solid #69778994;
  font-weight: 400;
  color: #5e6a7a;
  font-size: 1rem;
  padding: 0.25rem;
  text-align: right;
}
