.ms-ChoiceField:first-child {
  margin-top: 0 !important;
}

.ms-CalendarDay-dayIsToday {
  background-color: rgb(237, 235, 233) !important;
  color: rgb(95, 94, 94) !important;
}

.ms-CalendarDay-daySelected {
  background-color: #0078d4 !important;
  color: white !important;

  &:hover {
    background-color: #0078d4 !important;
    color: white !important;
  }
}

.ms-Pivot {
  display: flex;
  flex-wrap: wrap;
}

.ms-Toggle-label {
  font-weight: normal;
  font-size: 13px;
  margin-left: 0.75em;
}

.ms-Toggle-innerContainer {
  width: 35px;
}

.ms-Toggle-background {
  height: 17px;
}

.ms-Toggle-thumb {
  width: 6px;
  height: 6px;
  border-width: 6px;
}
