.loading-overlay-container {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  min-height: 2em;
  z-index: 999;
  cursor: wait;
  opacity: 0.7;
  background: rgba(0, 0, 0, 0.5);
}

.loading-overlay {
  width: 100%;
  height: 100%;
  background: url("TransparentBackground.png") repeat 0 0;
}

.loading-overlay-spinner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  transform: translateY(-50%);
}
