.offersection {
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}
.draftOfferListRow {
  color: var(--blue-secondary);
  cursor: pointer;
}

.offersList {
  .offer {
    a {
      cursor: pointer;
      text-decoration: none;
      color: var(--black);
    }
    .pr-2 {
      padding-right: 1rem;
    }
    .pr-3 {
      padding-right: 0.5rem;
    }
  }
}
