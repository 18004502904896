@import "styles/variables";

// Most of this scss file should be changed when we set up the Fabric UI library properly
.mittludvig-home {
  .linear-gradient {
    background: linear-gradient(-45deg, #514491, #3d5e86, #173355, #356eb3);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;
    padding: var(--whitespaceLarge);
    border-radius: 5px;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }

  .headline-one {
    font-weight: 600;
    height: $headline-one;
    color: var(--white);
  }

  .headline-two {
    font-size: 32px;
    color: var(--white);
  }

  .ml-new-box {
    min-height: 50vh;
  }

  @media (max-width: 1540px) {
    .headline-one {
      font-size: 56px !important;
      min-height: 56px;
    }

    .headline-two {
      font-size: 24px !important;
    }

    .quick-buttons {
      padding-top: 60px;
    }
  }
}

.post-image {
  max-height: 180px;
  border-radius: 5px;
  object-fit: cover;
}

.truncated-text > p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.placeholder-post-image {
  background-color: var(--gray-450);
  height: 180px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 80px;
    height: 80px;
    color: var(--gray-600);
  }
}
