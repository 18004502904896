.section {
  margin-left: var(--whitespaceMedium);
  margin-right: var(--whitespaceMedium);
  background: var(--white);
  padding: var(--whitespaceLarge);
  border: 1px solid var(--gray-450);
  display: flex;
  flex-direction: column;
}

.sectionBody {
  padding-top: var(--whitespaceMedium);
  padding-right: var(--whitespaceLarge);
  padding-left: var(--whitespaceLarge);
}
