.settings-category-card {
  height: 300px;
  margin-right: 10px;
  padding: 25px;
  border: 1px solid var(--gray-40);
  margin-bottom: 30px;
  background-color: var(--white);
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
  color: var(--gray-700);

  .toggle-container {
    overflow: auto;
    height: 90%;
  }

  .option-title {
    width: 50%;
  }
}
