.services-table-header {
  background: var(--gray-300);
  box-shadow: var(--box-shadow-sm);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--whitespaceLarge);

  .contentHeader--counter {
    font-size: var(--whitespaceMedium);
    font-weight: 400;
    color: var(--gray-650);
  }
}

.services-table {
  display: flex;
  flex-direction: column;

  .sticky-header {
    position: sticky;
    z-index: 1;
    top: 0;
    background: var(--white);
  }
}

.wrapper {
  transform: translateX(12px);
  position: relative;
}

.box-background {
  display: flex;
  padding: var(--whitespaceSmall);
  background: var(--gray-300);

  &.details {
    padding-left: var(--whitespace12);
    background: var(--gray-300);
  }

  &.current-month {
    background: var(--gridCurrent);
  }

  &.details-card {
    padding-left: var(--whitespace12);
    padding-right: var(--whitespace12);
  }
}

.timeline-arrow {
  display: flex;
  align-items: center;
  padding-left: var(--whitespaceExtrasmall);
  padding-right: var(--whitespaceExtrasmall);
  height: var(--whitespaceExtralarge);
  background: var(--gray-400);
  cursor: pointer;
  user-select: none;

  &.current-month {
    background: var(--primary-lightest);
  }
}

.dropdown-view {
  > div {
    border-bottom: 0 !important;

    &:focus-within {
      outline: none !important;
    }

    &::after {
      border-bottom: none !important;
    }

    button {
      font-weight: 600;
    }
  }

  &.search-by-dropdown {
    width: 230px;
  }

  grid-template-columns: auto 1fr;
}

.customer-details-pill {
  border-radius: var(--whitespaceSmall) !important;
  background: var(--gray-450) !important;
}

.assignment-team {
  flex-direction: column;
}

.w-customer-details {
  width: 20%;
}

.w-timeline {
  width: 80%;
}

.unfinished-activities-indicator {
  position: absolute;
  left: 0;
  width: 5px;
  height: 100%;
  padding: 0px;
  background: var(--redLight);
  border-radius: 0px 4px 4px 0px;
  z-index: 9999;
}

.unfinished-activities-skeleton {
  background-image: linear-gradient(
    to right,
    var(--redLightest) 0%,
    var(--redLight) 50%,
    var(--redLightest) 100%
  ) !important;
}

.unfinished-activities-accordion {
  .accordion-item-header2 {
    padding-top: var(--whitespaceExtrasmall);
    padding-left: var(--whitespaceMedium);
    padding-right: var(--whitespaceMedium);
    padding-bottom: 0;
  }

  .accordion-item-content2 {
    margin: 0;
  }

  &.empty {
    .accordion-item-header2 {
      padding-top: 0;
      padding-left: var(--whitespaceMedium);
      padding-right: var(--whitespaceMedium);
      padding-bottom: 0;
    }
  }
}

.unfinished-activities-loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 5px;
  transform-origin: top left;
  z-index: 9999;
}

.sort-button {
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding: var(--whitespaceSmall);

  &:hover {
    color: var(--teamsBrandBtn);
    background-color: unset;
  }

  &:active {
    background-color: var(--gray-450);
  }
}

.filter-button {
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding: 5px;
  border-radius: 2px;
  font-weight: 500 !important;

  &:hover {
    color: var(--teamsBrandBtn);
  }

  &:active {
    background-color: var(--gray-450);
  }
}

.active-state-bg {
  background-color: var(--gray-450);
  color: var(--teamsBrandBtn);
}

.empty-person-picker-bg {
  background: var(--gray-400);
  border-radius: 20px;
}
