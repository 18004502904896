.footer {
  width: 100%;
  max-width: 100%;
  min-height: var(--footer-height);

  padding-top: 12px;
  padding-bottom: 12px;

  border-top: 1px solid var(--gray-450);
  background-color: var(--gray-300);
  box-shadow: var(--box-shadow-xs);
}

.vertical-wizard-divider {
  border-left: 1px solid var(--gray-450);
  padding: 3px;
}

.wizard-stepper {
  position: relative;

  div:nth-child(2n-1) {
    display: inline-block !important;
    width: max-content;
  }

  div:nth-child(2n-2) {
    display: inline-block !important;
    width: 5vw;
    color: var(--gray-700);
  }

  .stepLabel {
    color: var(--gray-600);
    top: 1px;

    &.active {
      font-weight: 700;
      color: var(--gray-900) !important;
    }

    &.valid {
      color: var(--gray-700);
    }

    &.stepLabel.passed {
      color: var(--gray-900) !important;
    }
  }

  .stepNumber {
    color: var(--white);
    background-color: var(--gray-500);
    display: inline-block;
    line-height: 0px;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 500;

    span {
      display: inline-block;
      padding-top: 50%;
      padding-bottom: 50%;
      margin-left: 8px;
      margin-right: 8px;
    }

    &.active {
      background-color: var(--blue) !important;
    }

    &.valid {
      background-color: var(--blueLight);
    }

    &.passed {
      background-color: var(--blue) !important;
    }
  }

  .substep {
    padding-left: 6px;

    .substepNumber {
      color: transparent;
      background-color: var(--gray-600);
      display: inline-block;
      line-height: 0px;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      font-weight: 500;

      span {
        display: inline-block;
        margin-left: 5px;
        margin-right: 5px;
      }

      &.active {
        background-color: var(--blue) !important;
      }

      &.valid {
        background-color: var(--blueLight) !important;
      }

      &.passed {
        background-color: var(--blue) !important;
      }
    }

    .substepLabel {
      color: var(--gray-600);
      position: relative;
      top: 6px;

      &.active {
        font-weight: 700;
        color: var(--gray-900) !important;
      }

      &.valid {
        color: var(--gray-700);
      }

      &.passed {
        color: var(--gray-900) !important;
      }
    }
  }
  @media (max-width: 1500px) {
    div:nth-child(2n-2) {
      width: 3vw;
    }
  }
  @media (max-width: 1325px) {
    .stepLabel,
    .substepLabel {
      display: none;
    }
    div:nth-child(2n-2) {
      width: 7vw;
    }
  }
}
