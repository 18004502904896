$radius: 3px;

.service-box {
  background: var(--white);
  border-radius: $radius;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  transition: 0.2s background;
  animation-name: fadeIn;
  animation-duration: 0.4s;
  height: 73px;
  min-width: 235px;
  border-radius: var(--whitespaceSmall);

  .service-box-top-wrapper {
    width: 80%;
  }
  .service-name {
    // calculate width to be 65% minus the vertical divider element
    width: calc(65% - var(--whitespaceSmall) - var(--whitespaceSmall));
  }

  .service-frequency {
    width: 35%;
  }

  .service-name p,
  .service-frequency p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background: var(--gray-300);
  }

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  .frequency-label label {
    background-color: transparent;
    color: var(--teamsBrandBg-200);
    font-size: 14px;
    padding: 0 !important;
    opacity: 1 !important;
  }

  &.overdue {
    background: #fde7e9;

    &:hover {
      background: var(--gray-300);
    }
  }

  &.completed {
    background: var(--primary);
    color: rgba(255, 255, 255, 0.7);
    height: auto !important;
    border-radius: var(--whitespaceSmall);

    &:hover {
      background: var(--primary-highlight);
    }
  }

  &:hover {
    background: var(--white);
  }

  &.completed-collapsed {
    height: auto !important;
    background-color: unset;

    .services-number {
      color: var(--white);
      background-color: var(--primary);
      border-radius: 50%;
      width: var(--whitespaceLarge);
      height: var(--whitespaceLarge);
      text-align: center;
      line-height: 24px;
    }

    p {
      line-height: 20px;
    }
  }

  .service-box-progress-bar {
    border-radius: 0 0 $radius $radius;
    overflow: hidden;
    height: 6px;
    width: 100%;
    bottom: 0;
    position: relative;
    bottom: 0;
    min-height: 5px;

    &::-webkit-progress-value {
      background: var(--primary);
    }

    &::-webkit-progress-bar {
      background: var(--gray-500);
    }
  }
}
