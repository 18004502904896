$card-image-height: 120px;

.card {
  .card-image {
    user-select: none;
    position: relative;
    width: 100%;
    height: $card-image-height;
    cursor: pointer;

    &:hover {
      .card-overlay {
        opacity: 1;
      }
    }

    .card-label {
      position: absolute;
      z-index: 2;
      top: 0.5em;
      right: 0em;
      background: var(--ludvig-yellow);
      padding: 0.25em 0.5em;
    }

    .card-image-top {
      object-fit: cover;
      height: $card-image-height;
      width: 100%;
    }

    .card-overlay {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: 0.2s opacity;
    }
  }

  .card-title {
    font-size: 1.25em;
  }

  .card-text {
    font-size: 0.85em;
  }
}
