.wizard-header {
  .vertical-divider {
    border-left: 1px solid var(--gray-450);
    height: 100% !important;

    margin-left: var(--whitespaceLarge);
    padding-right: var(--whitespaceLarge);
  }

  .comment-icon {
    font-size: 24px;
  }
}
