.toggle-wrapper {
  padding: 2rem;
  border: 1px solid var(--gray-40);
}

.vertical-line {
  display: "inline-block";
  border-left: 1px solid red;
  height: 50%;
}

.half-row {
  width: 47.5%;
  display: "inline-block";
}

.margin-5-percent {
  margin-right: 5%;
}

.category {
  border-bottom: 5px solid var(--gray-40);
}

.primary-colored-text {
  color: var(--primary);
}

.width-20 {
  width: "20%";
}

.full-width {
  width: 100%;
}

.text-area-size {
  min-width: "200px";
  min-height: "250px";
}

.text-area-width {
  width: "500px";
}

.modal-fs {
  font-size: 18px;
}

.customer-settings-section {
  border: 1px solid var(--gray-450);
}

.margin-right-5-percent {
  margin-right: 5%;
}

.panel-width {
  min-width: 20%;
  max-width: 28%;
}

.scrollable-content {
  overflow-x: auto;
  scroll-behavior: smooth;
  max-height: 650px;
}

.row-muted {
  background: var(--gray-10);
  div {
    background: var(--gray-10);
  }
}

.servicesGroupedByHeaderItem {
  border-left: 1px solid var(--gray-500);
  padding-left: var(--whitespaceSmall);
  margin-left: var(--whitespaceSmall);
}

.customerSettingsContractsTable {
  td {
    font-size: 14px;
  }
}

.vertical-divider {
  border-left: 1px solid var(--gray-450);
  height: 20px !important;
  position: inherit;
}

.number-columns-alignment-right {
  display: flex !important;
  justify-content: flex-end !important;
}

.customer-settings-table-sticky,
.header-sticky {
  position: sticky;
  top: 0;
  padding-top: var(--whitespaceLarge);
  margin-top: var(--whitespaceLarge);
  z-index: 1;
}

.new-page-content {
  margin-top: calc(var(--whitespaceLarge) * -1);
  height: auto;
  position: relative;
}

.service-line-dropdown {
  background-color: transparent !important;
  border: none !important;
}
