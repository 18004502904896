.deals-table {
  border-collapse: separate;
  border: none;
  border-spacing: var(--whitespaceMedium) 0;

  margin-bottom: 5vh;

  th {
    padding-left: 0;
  }
}
.deals-vertical-divider {
  border-left: 1px solid var(--gray-600);
  height: 20px !important;
}
.deals-warning-icon{
  color: var(--yellow)
}