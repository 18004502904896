.tooltip-wrapper {
  display: inline-block;
}

.tooltip {
  position: fixed;
  z-index: 10000;
  transform: translateX(-50%);

  .tooltip-content {
    white-space: pre-wrap;
    text-align: center;
    position: relative;
    z-index: 100;
    border-radius: 4px;
    padding: 0.75em;
    color: var(--tooltip-text-color);
    background: var(--tooltip-background-color);
    font-size: 14px;
    line-height: 1;
    box-shadow: var(--box-shadow);

    &.small {
      width: 10vw;
    }

    &.medium {
      width: 25vw;
    }

    &.large {
      width: 40vw;
    }
  }

  .tooltip-tip {
    &::before {
      z-index: 101;
      content: " ";
      left: 50%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: var(--tooltip-arrow-size);
      margin-left: calc(var(--tooltip-arrow-size) / 2 * -2);
      box-shadow: 1px 1px 1px var(--gray-60);
      transform: rotate(45deg);
      bottom: -6px;
      border-color: var(--tooltip-background-color);
    }
  }

  .tooltip-tip-above {
    &::before {
      z-index: 101;
      content: " ";
      left: 50%;
      border: solid transparent;
      position: absolute;
      pointer-events: none;
      border-width: var(--tooltip-arrow-size);
      margin-left: calc(var(--tooltip-arrow-size) / 2 * -2);
      box-shadow: 1px 1px 1px var(--gray-60);
      transform: rotate(-135deg);
      bottom: 29px;
      border-color: var(--tooltip-background-color);
    }
  }

  .flex-column-tooltip-content {
    display: flex;
    flex-direction: column;
    row-gap: var(--whitespaceSmall);
  }
}

.tooltip-v9-content {
  display: block;
  text-align: center;
  font-weight: 400;

  &.text-bold {
    font-weight: 700
  }
}