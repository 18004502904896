.cs-contact-card {
  margin-right: 10px;
  border: 1px solid var(--gray-40);
  position: relative;
  margin-bottom: 30px;
  background-color: var(--white);
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
  color: var(--gray-700);
  border-radius: var(--whitespaceExtrasmall);

  .cs-contact-card-content {
    padding: 0 25px 25px;
  }

  &-row {
    &-title {
      display: block;
      padding-right: 10px;
      overflow: hidden;
    }

    &-text {
      display: block;
      font-weight: 500;
      padding-left: 4px;
      overflow: hidden;
    }
  }
  .edit-icon {
    position: absolute;
    top: 5px;
    right: 10px;
  }

  .contextual-menu {
    position: absolute;
    top: 5px;
    right: -45px;
  }

  .ribbon-wrapper {
    height: 25px;
    width: 15px;
    margin-left: 7px;
    margin-top: -2px;

    .ribbon {
      background-color: var(--primary);
      text-align: left;
    }
    .ribbon:before,
    .ribbon:after {
      content: "";
      position: absolute;
      background-color: inherit;
    }
    .ribbon,
    .ribbon:before,
    .ribbon:after {
      width: 15px;
      height: 15px;
      border-top-right-radius: 30%;
    }

    .ribbon {
      transform: rotate(-1deg) skewX(-50deg) scale(1, 0.866);
    }
    .ribbon:before {
      transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
        translate(0, -50%);
    }
    .ribbon:after {
      transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
    }
  }
}
