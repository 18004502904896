.label {
  padding: 3px 5px;
  background: var(--gray-20);
  border-radius: 2px;
  user-select: none;

  &.sm {
    font-size: 10px;
  }

  &.md {
    font-size: 12px;
  }

  &.lg {
    font-size: 16px;
  }

  &.success {
    background: var(--green);
    color: var(--white);
  }

  &.selected {
    opacity: 1;
  }

  &.purple {
    background: var(--purple);
    color: var(--white);
  }

  &.green {
    background: var(--green);
    color: var(--white);
  }

  &.blue {
    background: var(--primary);
    color: var(--white);
  }

  &.yellow {
    background: var(--yellow);
    color: var(--white);
  }

  &.lightblue {
    background: var(--blueLight);
    color: var(--white);
  }
}
