$fontWeight-600: 600;

.current-accounting-content {
  .ms-ChoiceField {
    margin-top: 0;
  }

  .current-accounting-service-header {
    font-weight: $fontWeight-600;

    button {
      font-weight: $fontWeight-600;
    }
  }
}

.service-row {
  height: auto;
  padding: var(--whitespaceSmall) 1px;
}

.value-col,
.frequency-col {
  > div:first-child {
    width: 70%;
  }
}

.current-accounting-tab {
  width: 12.5%;
  &-content {
    span {
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
      overflow: hidden !important;
    }
  }
}
