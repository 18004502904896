.accordion-button {
  background-color: var(--gray-10);
}

.accordion-button:not(.collapsed) {
  background-color: var(--gray-10);
  border-bottom: 1px solid lightgrey;
}

.accordion-subtitle {
  color: #858585;
}

.accordion-custom-item2 {
  margin-top: var(--whitespaceMegaSmall);
  border-radius: var(--whitespaceMegaSmall);
  display: flex;
  flex-direction: column;

  .accordion-item-header2 {
    display: flex;
    padding: var(--whitespaceMedium) var(--whitespaceLarge);
    cursor: pointer;
  }

  &-arrow {
    margin: 5px;
  }

  .accordion-item-content2 {
    margin: 0 var(--whitespaceExtralarge) var(--whitespaceMedium);
    padding: 0 var(--whitespaceExtrasmall);
  }

  .cs-content {
    background: var(--white);
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
  }
}
