.detailed-info-category {
  .subcategory-text {
    color: var(--gray-900);
    width: fit-content;
    border-top: 1px solid var(--gray-450);
  }

  .title {
    color: #858585;
  }

  .content {
    color: var(--gray-900);
  }

  .btn-outline-link {
    color: #0078d4;
    text-decoration: underline;
  }
}
