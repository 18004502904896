.paddedRow {
  padding-bottom: 25px;
}
.custom-link {
  text-decoration: none;
  color: var(--black);
  &:hover {
    color: var(--red);
  }
}

.card.red {
  background: var(--red);
  color: var(--white);
}

.card.green {
  background: var(--green);
  color: var(--gray-190);
}

.card.yellow {
  background: var(--yellow);
  color: var(--gray-190);
}
