.ring-chart {
  .drop-shadow {
    fill: #fff;
    filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.25));
  }

  .inner-shadow {
    fill: #fff;
    box-shadow: inset 5em 1em gold;
    filter: drop-shadow(inset 0px 5px 5px rgba(0, 0, 0, 0.25));
  }
}
