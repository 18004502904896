.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-disabled {
  cursor: not-allowed;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-border {
  border: 0 !important;
}

.flex-1-1 {
  flex: 1 1;
}

.flex-1 {
  flex: 1 !important;
}

.flex-none {
  flex: none !important;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-2 {
  flex-shrink: 2;
}

.icon-blue {
  color: var(--blue);
  cursor: pointer;
}

.flex-direction-column {
  flex-direction: column;
}

$utilities: (
  "box-shadow": (
    property: box-shadow,
    class: box-shadow,
    values: (
      xs: var(--box-shadow-xs),
      md: var(--box-shadow-md),
    ),
  ),
  "border-radius": (
    property: border-radius,
    class: border-radius,
    values: (
      2: 2px,
      4: 4px,
      6: 6px,
      8: 8px,
      10: 10px,
    ),
  ),
  "position": (
    property: position,
    class: position,
    values: (
      relative,
      absolute,
      fixed,
      static,
      sticky,
    ),
  ),
  "gap": (
    property: gap,
    class: fg,
    values: (
      1: 0.25rem,
      2: 0.5rem,
      3: 1rem,
      4: 1.5rem,
    ),
  ),
  "opacity": (
    property: opacity,
    class: o,
    values: (
      0: 0,
      25: 0.25,
      50: 0.5,
      75: 0.75,
      100: 1,
    ),
  ),
  "font-size-em": (
    property: font-size,
    class: fem,
    values: (
      1: 1em,
      2: 1.25em,
      3: 1.5em,
      4: 1.75em,
      5: 2em,
      6: 3em,
      7: 6em,
    ),
  ),
  "font-size-px": (
    property: font-size,
    class: fpx,
    values: (
      10: 10px,
      11: 11px,
      12: 12px,
      13: 13px,
      14: 14px,
      16: 16px,
      18: 18px,
      20: 20px,
      24: 24px,
    ),
  ),
  "font-weight": (
    property: font-weight,
    class: fw,
    values: (
      100: 100,
      200: 200,
      300: 300,
      400: 400,
      500: 500,
      600: 600,
      700: 700,
      800: 800,
      900: 900,
    ),
  ),
  "font-style": (
    property: font-style,
    class: fs,
    values: (
      inherit,
      initial,
      italic,
      normal,
      oblique,
      revert,
      unset,
    ),
  ),
  "width": (
    property: width,
    class: vw,
    values: (
      10: 10vw,
      15: 15vw,
      20: 20vw,
      30: 30vw,
      40: 40vw,
      50: 50vw,
      60: 60vw,
      70: 70vw,
      80: 80vw,
      90: 90vw,
    ),
  ),
  "min-height": (
    property: min-height,
    class: min-height,
    values: (
      32: 32px,
      45: 45px,
      80: 80px,
      100: 100px,
      200: 200px,
      250: 250px,
      300: 300px,
      400: 400px,
      500: 500px,
      600: 600px,
      700: 700px,
    ),
  ),
  "max-height": (
    property: max-height,
    class: max-height,
    values: (
      20: 20px,
      300: 300px,
      400: 400px,
    ),
  ),
  "bg": (
    property: background-color,
    class: bg,
    values: (
      gray-300: var(--gray-300),
      gray-400: var(--gray-400),
      gray-450: var(--gray-450),
      gray-500: var(--gray-500),
      white: var(--white),
      blueLightest: var(--blueLightest),
      redLightest: var(--redLightest),
    ),
  ),
  "color": (
    property: color,
    class: color,
    values: (
      blue: var(--blue),
      red: var(--red),
      redLight: var(--redLight),
      listLinkColor: var(--listLinkColor),
      gray500: var(--gray-500),
      gray700: var(--gray-700),
      gridLinkColor: var(--gridLinkColor),
      white: var(--white),
      primary: var(--primary),
    ),
  ),
  "min-width": (
    property: min-width,
    class: min-width,
    values: (
      35: 35px,
      170: 170px,
      250: 250px,
      300: 300px,
      400: 400px,
      450: 450px,
      500: 500px,
      900: 900px,
      1400: 1400px,
    ),
  ),
  "transform-x": (
    property: transform,
    class: transform-x,
    values: (
      5: translateX(5px),
      4: translateX(4px),
      3: translateX(3px),
      2: translateX(2px),
      1: translateX(1px),
    ),
  ),
  "transform-y": (
    property: transform,
    class: transform-y,
    values: (
      5: translateY(5px),
      4: translateY(4px),
      3: translateY(3px),
      2: translateY(2px),
      1: translateY(1px),
    ),
  ),
  "w": (
    property: width,
    class: w,
    values: (
      10: 10%,
      20: 20%,
      30: 30%,
      35: 35%,
      40: 40%,
      50: 50%,
      60: 60%,
      70: 70%,
      80: 80%,
      90: 90%,
      100: 100%,
    ),
  ),
  "vertical-align": (
    property: vertical-align,
    class: v-align,
    values: (
      top: top,
      middle: middle,
      bottom: bottom,
      baseline: baseline,
      text-top: text-top,
      text-bottom: text-bottom,
      sub: sub,
      super: super,
    ),
  ),
  "pointer-events": (
    // https://developer.mozilla.org/en-US/docs/Web/CSS/pointer-events
    property: pointer-events,
    class: pointer-events,
    values: (
      // Keyword values
      auto: auto,
      none: none,

      // Values used in SVGs
      visiblePainted: visiblePainted,
      visibleFill: visibleFill,
      visibleStroke: visibleStroke,
      visible: visible,
      painted: painted,
      fill: fill,
      stroke: stroke,
      bounding-box: bounding-box,
      all: all,

      // Global values
      inherit: inherit,
      initial: initial,
      revert: revert,
      revert-layer: revert-layer,
      unset: unset
    ),
  ),
);

.header-blue-stripe {
  padding-left: 0.3rem;
  border-left: 0.13rem solid var(--ms-color-sharedCyanBlue10);
}

.display-grid-center {
  display: grid;
  place-items: center;
}

.text-wrap-none {
  white-space: nowrap;
  word-break: keep-all;
}

.text-wrap {
  white-space: break-spaces;
  word-break: break-word;
  hyphens: auto;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left !important;
}

.text-end {
  text-align: end;
}

.text-center {
  text-align: center;
}

.text-decoration-none {
  text-decoration: none;
}

.fw-bold {
  font-weight: bold;
}

.fw-semibold {
  font-weight: 600;
}

.text-gray {
  color: var(--gray-650) !important;
}

.horizontal-divider {
  border-top: 1px solid var(--gray-450);

  // TODO check why it is added
  // @media (min-width: 1500px) {
  //   display: none;
  // }
}

.vertical-divider {
  border-left: 1px solid var(--gray-450);
  position: relative;
  padding: 3px;
  top: 15px;
  left: 12px;
  height: 80%;
}

.verticalDivider::before {
  content: "";
  position: absolute;
  height: 150px;
  width: 1px;
  border-left: 1px solid var(--gray-450);
  display: inline-block;
  transform: translateX(-17px);
  margin-top: 10px;
}

.inline-vertical-divider {
  border-left: 1px solid var(--gray-450);
  position: relative;
  padding-right: 0.4rem;
  margin-left: 0.4rem;
}

.horizontal-scroll-auto {
  overflow: auto hidden;
  scroll-behavior: smooth;
  white-space: nowrap;
}

.min-height-200 {
  min-height: 200px;
}

.min-height-500 {
  min-height: 500px;
}

.blue-link-underline {
  color: var(--blue) !important;
  text-decoration: underline !important;
  cursor: pointer !important;

  &:hover {
    color: var(--blueLight) !important;
  }

  &:active {
    color: var(--listLinkColor) !important;
  }

  &:disabled {
    color: var(--gray-600) !important;
    cursor: not-allowed !important;
  }
}

.blue-link {
  color: var(--blue) !important;

  &:hover {
    color: var(--blueLight) !important;
    cursor: pointer;
  }
}

.blue-link-hover {
  &:hover {
    color: var(--blueLight) !important;
    cursor: pointer;
  }
}

.horizontal-scroll {
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  white-space: nowrap;
}

.vertical-scroll {
  overflow-x: hidden;
  overflow-y: scroll;
  white-space: nowrap;
  max-height: 300px;
}

.showMe {
  animation: cssAnimation 0s 5s forwards;
  visibility: hidden;
}

.bold-icon i {
  font-weight: 600 !important;
}

.pog-spinner {
  position: absolute;
  bottom: 50%;
  right: 50%;
}

.footer {
  border-top: 1px solid var(--gray-30);
  width: calc(100% - var(--sidebar-width));
  max-width: 100%;
  background-color: var(--ms-color-gray1);
  box-shadow: var(--box-shadow-xs);
  position: fixed;
  bottom: 0;
  min-height: var(--footer-height);
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: var(--gray-5);
}

.white-hover {
  &:hover {
    background: var(--white);
    cursor: pointer;
  }
}

.vertical-divider {
  border-left: 1px solid var(--gray-450);
  height: 30% !important;
}

.headline-one {
  font-size: var(--headline-one);
}

.border-ms-20 {
  border: 1px solid var(--gray-20);
}

.padding-primary {
  padding: var(--whitespaceLarge);
}

.bottom-border {
  border-bottom: 1px solid var(--gray-450);
}

.info-label {
  color: var(--gray-110);

  & span {
    color: var(--text-dark);
  }
}

.header-title {
  h3 {
    position: relative;
    font-weight: 400;
    &:before {
      content: "";
      height: 20px;
      width: 2px;
      position: absolute;
      left: 0px;
      top: 5px;
      background-color: var(--blue);
    }
  }
}
.header-subtitle {
  margin-top: 12px;
  margin-bottom: 0;
  color: var(--gray-700);
  font-style: italic;
  font-weight: 400;
}

.title-with-separator {
  div {
    position: relative;
    padding-left: var(--whitespaceSmall);
    &:before {
      content: "";
      height: 100%;
      width: 2px;
      position: absolute;
      left: 0px;
      top: 0px;
      background-color: var(--blue);
    }
  }
}

.minHeight-100 {
  min-height: 100%;
}

.minHeight-80 {
  min-height: 80%;
}

.section-secondary {
  border: 1px solid var(--gray-20);
  border-radius: 4px;
  padding: var(--whitespaceLarge);
  margin-bottom: var(--whitespaceLarge);
}

.section-primary-extended {
  background: var(--white);
  padding: var(--whitespaceLarge);
  box-shadow: var(--box-shadow-md);
  border: var(--gray-80) !important;
  border-radius: 4px;
  margin: var(--whitespaceLarge);
  height: calc(100vh - (var(--header-height) + (2 * var(--whitespaceLarge))));
  overflow-y: auto;
}

.stringLength {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.min-width-fit-content {
  min-width: fit-content !important;
}

.width-250 {
  width: 250px;
}

.width-180 {
  width: 180px;
}

.bgBlueLight {
  background-color: var(--blueLight);
}
.bgBlueLightest {
  background-color: var(--blueLightest);
}

@keyframes cssAnimation {
  to {
    visibility: visible;
  }
}

.hideMe {
  animation: cssAnimation 0s 5s forwards;
  visibility: visible;
}

.disabled {
  pointer-events: none !important;
  opacity: 0.5;
}

@keyframes cssAnimation {
  to {
    visibility: hidden;
  }
}

.micorosoft-icon-24-local-size {
  height: 19px;
  width: 19px;
}

.border-bottom-gray {
  border-bottom: 1px solid var(--gray-450) !important;
}

.border-top-gray {
  border-top: 1px solid var(--gray-450) !important;
}

.no-bottom-border {
  border-bottom: none !important;
}

.xs-table-border-spacing {
  border-spacing: var(--whitespaceExtrasmall);
}

.mitt-ludvig-header h3::before {
  content: "";
  height: 20px;
  width: 2px;
  position: absolute;
  left: 8px;
  bottom: 20px !important;
  background-color: var(--primary);
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.position-static {
  position: static;
}

@media (max-width: 1830px) {
  .col-breakpoint-1830 {
    width: 100% !important;
  }

  .offset-breakpoint-1830 {
    margin-left: 16.66666667% !important;
  }

  .mt-auto {
    margin-top: auto;
  }

  .fit-content {
    width: fit-content;
  }
}

.ghost-button {
  display: inline-block;
  padding: 8px;
  border: none !important;
  text-align: center;
  outline: none;
  text-decoration: none;
  background: none !important;

  &:hover {
    background-color: transparent;
  }

  &:active {
    background-color: transparent;
  }
}

.text-max-char {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.background-gray-400 {
  background-color: var(--gray-400);
}

.background-gray-300 {
  background-color: var(--gray-300);
}

.fit-content {
  width: fit-content !important;
}

.fit-content-min {
  min-width: fit-content !important;
}

.max-content-min {
  min-width: max-content !important;
}

.unset-min {
  min-width: unset !important;
}

.background-gray-400 {
  background-color: var(--gray-400);
}

.background-gray-300 {
  background-color: var(--gray-300);
}

.text-max-char-100 {
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hidden {
  display: none;
}

.background-gray:hover {
  background-color: var(--gray-450);
}

.mittludvig-header-icon {
  color: red !important;
}

.text-align-center {
  text-align: center;
}

.width-max {
  width: max-content;
}

.align-items-center {
  align-items: center;
}

.align-content-flex-start {
  align-content: flex-start;
}

.justify-content-space-between {
  justify-content: space-between !important;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-flex-end {
  align-items: flex-end;
}

.white-space-pre-line {
  white-space: pre-line;
}

.sticky-button-container {
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 100%;

  .sticky-button-list {
    flex: 1;
  }

  .sticky-button-wrapper {
    position: sticky;
    bottom: calc(var(--whitespaceLarge) * (-1));
    width: 100%;
    background-color: white;
    padding-top: var(--whitespaceLarge);
  }

  &:hover {
    background-color: white !important;
  }
}

.zoom-2 {
  zoom: 2;
}

.icon-danger {
  color: var(--red);
}

.border-collapse-separate {
  border-collapse: separate;
}

.stack-horizontal {
  display: flex;
  flex-flow: row;
}

@each $direction in "x", "y", "" {
  @if $direction == "" {
    .no-overflow {
      overflow: hidden;
    }
  } @else {
    .no-overflow-#{$direction} {
      overflow-#{$direction}: hidden;
    }
  }
}
