// overwrite the default fUI styles to match the current design
.deal-dropdown {
  border: 1px solid var(--gray-800) !important;
  border-radius: 2px !important;
  &-disabled {
    border: none !important;
    background-color: var(--gray-400) !important;
  }
}
.deal-input {
  padding: 5px 12px !important;
}