.service-wrapper {
  position: relative;
  width: 305px;
  border: 1px solid var(--blue);
  border-radius: 4px;
  cursor: pointer;
  max-height: 192px;
  overflow: hidden;
  transition: 0.4s max-height;

  &:hover,
  &.selected {
    background-color: var(--blueLightest);
  }

  &.selected {
    max-height: 500px;
    overflow: auto;
    .service-selected {
      opacity: 1;
    }
  }
}

.service-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 2px;

  top: 0;
  right: 0;
  font-size: 1.25em;
  width: 1.25em;
  height: 1.25em;

  background-color: var(--blue);
  color: white;
  opacity: 0;
  transition: 0.05s opacity;
}

.service {
  padding-top: 35px;
  width: 100%;
  height: 190px;

  hr {
    width: 20%;
    margin: 20px auto;
    color: var(--gray-700);
  }

  .service-heading {
    color: var(--primary);
    font-weight: 500;
    font-size: 20px;
  }

  .service-description {
    color: var(--gray-700);
  }

  &.no-description {
    padding-top: 0px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap !important;
  }
}

.service-toggles {
  width: 100%;
  padding: 5px 10px;
  border-top: 1px solid var(--blue);

  .service-toggle {
    margin-bottom: 0.5em;
    margin-left: 0.25em;
  }

  .service-toggle:last-child {
    margin-bottom: 0;
  }

  &.selected {
    background-color: var(--blueLightest);
    border-top: none;
    border-left: 1px solid var(--blue);
    border-right: 1px solid var(--blue);
    border-bottom: 1px solid var(--blue);
  }
}
