.checkbox-custom-styling .fui-Checkbox__indicator {
  margin: 0;
}

.checkbox {
  label {
    margin-left: var(--whitespaceSmall);
    padding-top: 0;
    padding-bottom: 0;
  }
}
