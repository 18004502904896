$main-frame-top-padding: -1.125em;

.table-row-height-44 {
  height: 44px;
}
.table-fs-14 {
  td,
  th {
    font-size: 14px;
  }

  th {
    font-weight: 600;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  overflow-y: auto;
  border-spacing: 0;

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
  }

  td {
    font-size: 12px;
  }

  th {
    font-weight: 600 !important;
    font-size: 14px;
    white-space: nowrap;
    cursor: default;
  }

  th,
  td {
    padding: 12px 14px;
    &:first-child {
      padding-left: var (--whitespaceExtrasmall) !important;
    }
  }

  thead tr {
    border-top: 0;
  }

  th {
    border-bottom: 1px solid var(--gray-20);
  }

  tr {
    border-top: 1px solid var(--gray-20);
    border-bottom: 1px solid var(--gray-20);

    &.no-border-first-last {
      border-top: 1px solid var(--gray-20);
    }

    &.no-hover {
      &:hover {
        background: var(--white);
      }
    }

    &.group-header {
      background: var(--gray-20);

      td {
        padding: 14px 14px;
      }
    }

    &.success {
      background: var(--success);
    }

    &.warning {
      background: var(--warning);
    }

    &.danger {
      background: var(--danger);
    }
  }

  &.cell-p-0 {
    td,
    th {
      padding: 0;
    }
  }

  &.cell-p-1 {
    td,
    th {
      padding: 4px 4px;
    }
  }

  &.cell-p-2 {
    td,
    th {
      padding: 8px 8px;
    }
  }

  &.no-border {
    border: 0;

    td {
      border: 0;
    }
  }

  &.middle-align {
    td,
    td * {
      vertical-align: middle !important;
    }
  }

  &.top-align {
    td {
      vertical-align: top;
    }
  }

  &.sticky {
    th {
      top: calc(var(--whitespaceLarge) * -1);
      position: sticky;
      z-index: 1;
      box-shadow: 0 1px var(--gray-20); /* draw a bottom-border */
      background: var(--white);
    }
  }

  &.no-border-first-last {
    tr {
      &:first-child {
        border-top: 0;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  &.hover-effect {
    th,
    td {
      &:hover {
        background: var(--gray-20);
      }
    }

    tbody tr:hover {
      background: var(--gray-20);
    }
  }

  &.text-enlarge {
    td {
      font-size: 15px;
    }
  }
}
