.textarea {
  border: 1px solid var(--border-color);
  border-radius: 2px;
  padding: 0.25em 0.5em;

  &:disabled {
    background-color: hsl(0, 0%, 95%);
    border: 1px solid hsl(0, 0%, 95%);
    color: var(--input-disabled-color);
  }
}
