.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  
  .vertical-divider {
    height: 100% !important;
    min-height: 24px;
  }
}

.contentHeader {
  background-color: var(--gray-300);
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: var(--whitespaceLarge);
  // contentBody has no margin-top so we rely on the header to provide the spacing
  // if it is present. If it isn't, content will provide it through its padding
  box-shadow: var(--box-shadow-sm);
  z-index: 9999;

  > div:not(:first-child) {
    margin-left: var(--whitespaceSmall);
  }

  .contentTitleWrapper {
    height: 100%;
  }

  .contentHeader--title {
    position: relative;
    padding-left: var(--whitespaceSmall);
    margin-bottom: 0; // neutralize margin that comes from h-tag

    &:before {
      content: "";
      height: 20px;
      width: 2px;
      position: absolute;
      left: 0px;
      top: 5px;
      background-color: var(--blue);
    }
  }

  .contentHeader--indicator {
    color: var(--gray-650);
    font-size: var(--whitespaceMedium);
    padding-left: var(--whitespaceSmall);
    padding-right: var(--whitespaceSmall);
  }

  .vertical-divider {
    height: 100% !important;
    min-height: 24px;
  }
}

.contentHeaderTitle {
  display: flex;
  align-items: center;
  flex-direction: row;

  > div:not(:first-child) {
    margin-left: var(--whitespaceSmall);
  }

  .contentTitleWrapper {
    height: 100%;
  }

  .contentHeader--title {
    position: relative;
    padding-left: var(--whitespaceSmall);
    margin-bottom: 0; // neutralize margin that comes from h-tag

    &:before {
      content: "";
      height: 20px;
      width: 2px;
      position: absolute;
      left: 0px;
      top: 5px;
      background-color: var(--primary);
    }
  }

  %contentHeaderCounterBase {
    font-size: var(--whitespaceMedium);
    font-weight: 400;
  }
  .contentHeader--counter {
    @extend %contentHeaderCounterBase;
    color: var(--gray-650);
  }
  .contentHeader--selectionCounter {
    @extend %contentHeaderCounterBase;
  }

  .contentHeader--content {
    margin-left: auto !important;
  }
}

.contentSubHeader {
  color: var(--gray-650);

  &:not(:last-child) {
    padding-right: var(--whitespaceSmall);
    margin-right: var(--whitespaceSmall);
    border-right: 1px solid var(--gray-450);
  }

  b {
    font-weight: 600 !important;
  }
}

.contentBody {
  flex: 1;
  padding: var(--whitespaceLarge);
}

.overflow-y-auto {
  overflow-y: auto;
}

.contentFooter {
  padding: var(--whitespaceLarge);
}

.content--loading {
  opacity: 0.7;
}
