h1 {
  font-size: 32px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

.sub-h1 {
  font-size: 16px;
}

.body-italic {
  font-style: italic;
}

.body-italic-light {
  font-style: italic;
  color: var(--gray-120);
  font-weight: normal;
}

.body-light {
  color: var(--gray-700);
}

p {
  font-size: 14px;
}
