.accordion-button {
  background-color: var(--gray-10);
}

.accordion-button:not(.collapsed) {
  background-color: var(--gray-10);
  border-bottom: 1px solid lightgrey;
}

.accordion-custom-item {
  margin-left: var(--whitespaceMedium);
  margin-right: var(--whitespaceMedium);
  margin-top: var(--whitespaceMegaSmall);
  background: var(--white);
  border: 1px solid var(--gray-450);
  border-radius: var(--whitespaceMegaSmall);
  display: flex;
  flex-direction: column;

  .accordion-item-header {
    display: flex;
    padding: var(--whitespaceMedium) var(--whitespaceLarge);
    cursor: pointer;
    background-color: var(--gray-400);
  }

  &-arrow {
    margin: 5px;
  }

  .accordion-item-content {
    padding: var(--whitespaceMedium);
  }
}