.small {
  max-width: 20vw !important;
}

.small-to-mid {
  max-width: 35vw !important;
}

.medium {
  max-width: 40vw !important;
}

.large {
  max-width: 55vw !important;
}

.modal-header {
  margin-bottom: 1rem;
  .modal-title {
    &:before {
      content: "";
      height: 100%;
      width: 2px;
      position: absolute;
      top: 3px;
      background-color: var(--blue);
    }
  }
}

.modal-body {
  max-height: calc(-72px + 100vh) !important;
}

.modal-footer {
  margin-top: 1rem;
}

.is-warning {
  border-top: 4px solid var(--ludvig-red-text);
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.steps {
  color: var(--gray-600);
  height: 2em;
  width: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scrollable-content {
  overflow-x: auto;
  scroll-behavior: smooth;
  max-height: 650px;
}
