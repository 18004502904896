.reports-modal {
  .modal-dialog {
    width: 50vw;
    max-width: 600px;

    textarea {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 13px;
    }
  }
}

.download-button {
  &:hover {
    opacity: 80%;
  }
}
