.toaster {
  max-width: 30vw;
  width: auto !important;
}

.fui-Toast {
  display: flex;
  align-items: center;
  width: 100%;

  &.success-color {
    background: var(--greenLightest) !important;
  }

  &.error-color {
    background: var(--redLightest) !important;
  }

  &.warning-color {
    background: var(--yellowLightest) !important;
  }

  &.info-color {
    background: var(--blueLightest) !important;
  }
}


.notification-warning {
  color: var(--yellow);
}

.notification-info {
  color: var(--blue)
}

.notification-error {
  color: var(--redLight)
}

.notification-success {
  color: var(--greenLight)
}