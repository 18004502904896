.input {
  box-shadow: none;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  border: 1px solid var(--border-color);
  border-radius: 2px;
  background: rgb(255, 255, 255);
  cursor: text;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;

  .units {
    position: absolute;
    right: 0.5em;
    color: var(--gray-80);
    user-select: none;
    top: 0.25em;
    transition: 0.2s right;
  }

  input:not(:disabled) {
    &:hover + .units,
    &:focus + .units,
    &:active + .units {
      right: 2em;
      pointer-events: none;
    }
  }

  &.disabled {
    border: 1px solid #eee;
  }

  &.invalid {
    border: 1px solid var(--redLight);
  }

  input {
    font-weight: 400;
    box-shadow: none;
    margin: 0;
    padding: 0.325em 0.5em;
    box-sizing: border-box;
    border-radius: 0px;
    border: none;
    background: none transparent;
    color: rgb(50, 49, 48);
    width: 100%;
    min-width: 0px;
    text-overflow: ellipsis;
    outline: 0px;
    transition: 0.2s background;

    &.invalid {
      background: var(--input-invalid-background);
    }

    &.valid {
      background: var(--input-valid-background);
    }

    &:disabled {
      background: #eee;
      border: 0;
      color: var(--input-disabled-color);
    }
  }
}
