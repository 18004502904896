.circle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: var(--gray-450);
  width: 100%;
  height: 100%;
  border-radius: 50%;

  &:hover {
    background: rgba(238, 238, 238, 0.5);
  }
}

.disabled {
  opacity: 0.5;
}

.h-22 {
  height: var(--whitespaceLarge);
}

.h-34 {
  height: 34px;
}

.wrapper-32 {
  display: flex;
  align-items: center;
  height: var(--avatar-size);
}

.people-picker-search {
  position: absolute;
  top: 0;
  left: 40px;
  width: 88%;
  z-index: 999;
  transition: opacity 0.3s ease-in-out;

  .ms-Callout {
    top: 0 !important;
  }

  &.show {
    opacity: 1;
  }

  &.hide {
    opacity: 0;
  }
}

.user-avatar-wrapper {
  display: flex;
  align-items: center;

  &.multi-select {
    overflow-x: auto;
    overflow-y: hidden;
  }

  &::-webkit-scrollbar {
    height: var(--whitespaceExtrasmall);
  }
}

.persistent {
  opacity: 1;
}

.user-avatar {
  background: var(--gray-450);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.small {
    width: var(--avatar-size-s, var(--avatar-size, 24px));
    height: var(--avatar-size-s, var(--avatar-size, 24px));
  }
}
