.change-assignee-modal-body {
  .change-assignee-modal-person-pickers-row {
    display: flex;

    & > div:first-child,
    & > div:nth-child(3) {
      width: 45%;
    }

    & > div:nth-child(2) {
      width: 10%;
      text-align: center;
      margin-top: 16px;
    }
  }

  .header {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .header-cell {
    font-weight: 600;
  }
  .header-cell.sortable {
    cursor: pointer;

    &:hover {
      background-color: var(--gray-400);
    }

    &:active {
      background-color: var(--gray-470);
    }
  }

  .customer-row {
    &:hover {
      cursor: ns-resize;
    }
  }

  hr {
    color: var(--gray-470);
    opacity: 1;
  }
}
