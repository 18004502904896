$sizes: (
  "xxs": var(--whitespaceMegaSmall),
  "xs": var(--whitespaceExtrasmall),
  "sm": var(--whitespaceSmall),
  "md": var(--whitespaceMedium),
  "lg": var(--whitespaceLarge),
  "xl": var(--whitespaceExtralarge),
  "xxl": var(--whitespaceExtraExtralarge),
  "0": 0,
);
@each $sizeName, $size in $sizes {
  .p-#{$sizeName} {
    padding: $size !important;
  }
  .pt-#{$sizeName} {
    padding-top: $size !important;
  }
  .pb-#{$sizeName} {
    padding-bottom: $size !important;
  }
  .pl-#{$sizeName} {
    padding-left: $size !important;
  }
  .pr-#{$sizeName} {
    padding-right: $size !important;
  }
  .py-#{$sizeName} {
    padding-top: $size !important;
    padding-bottom: $size !important;
  }
  .px-#{$sizeName} {
    padding-left: $size !important;
    padding-right: $size !important;
  }
  .m-#{$sizeName} {
    margin: $size !important;
  }
  .mt-#{$sizeName} {
    margin-top: $size !important;
  }
  .mb-#{$sizeName} {
    margin-bottom: $size !important;
  }
  .ml-#{$sizeName} {
    margin-left: $size !important;
  }
  .mr-#{$sizeName} {
    margin-right: $size !important;
  }
  .my-#{$sizeName} {
    margin-top: $size !important;
    margin-bottom: $size !important;
  }
  .mx-#{$sizeName} {
    margin-left: $size !important;
    margin-right: $size !important;
  }
  .fg-#{$sizeName} {
    gap: $size !important;
  }

  .gy-#{$sizeName} {
    --bs-gutter-y: #{$size};
  }
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.m-auto {
  margin: auto;
}
