.selected-customer-row {
  background-color: var(--blueLightestDarken2);

  .box-background.details-card {
    background-color: var(--blueLightest);
  }

  .bg-monthColumn {
    background-color: var(--blueLightest);
  }

  .bg-currentMonthColumn {
    background-color: var(--blueLightestDarken4);
  }
}
