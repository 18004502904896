.summary-table {
  .row {
    padding: 1rem;
    min-height: 36px;
    max-height: 36px;
    border-top: 1px solid #eee;

    &.header {
      background: var(--gray-20);
      border-bottom: 1px solid #eee;

      & span {
        font-size: 18px;
        font-weight: 500;
      }
    }
    &.sub-header {
      font-weight: bold;
      border-top: none;
    }
    &.price-footer {
      background: var(--gray-5);
    }
  }
}

.final-amount div {
  color: var(--greenLight);
  &:first-child {
    font-size: 35px;
    font-weight: 600;
  }
  &:second-child {
    font-size: 25px;
    display: block;
  }
}

.scrollable-content {
  overflow-x: auto;
  scroll-behavior: smooth;
  max-height: 650px;
}

.no-shadow {
  box-shadow: none !important;
}

.final-price {
  background-color: #ebf9f6 !important;
}

.copy-link-icon {
  margin-top: -60px;
}

.summary-texts-wrapper {
  h3 {
    font-size: 14px;
  }
}

.approve-service-category-toggle {
  margin-top: -4px;
}
