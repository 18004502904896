table tr.completed {
  opacity: 0.5;
}

table tr.overdue {
  background: #ffeded;
}

table td .on-hover {
  opacity: 0;
  transition: 0.2s opacity;
}

table tr:hover .on-hover {
  opacity: 1;
}

.activity-row {
  &.selected {
    background: rgba(132, 255, 132, 0.3);
  }
}
