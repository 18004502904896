$radius: 3px;

.deal {
  background: var(--white);

  border-right-width: 5px;
  border-right-style: solid;
  border-radius: $radius;

  &-name {
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  cursor: pointer;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  transition: 0.2s background;
  animation-name: fadeIn;
  animation-duration: 0.4s;
  padding: var(--whitespaceExtrasmall);

  border-right: var(--gray-600) 5px solid;
  border: var(--gray-40) 1px solid;
  order: var(--gray-40) 1px solid;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &.proactive-highlight {
    border-right: 5px solid var(--primary);
  }

  &.new {
    background: var(--ludvig-green);
  }

  &.priority-high {
    background: var(--ludvig-red);

    &:hover {
      background: var(--ludvig-red-highlight);
    }
  }

  &.priority-medium {
    background: var(--ludvig-yellow);

    &:hover {
      background: var(--yellowLight);
    }
  }

  &.overdue {
    background: #fde7e9;

    &:hover {
      background: #fef8fa;
    }
  }

  &.rejected {
    opacity: 0.5;
  }

  &.signed {
    background: var(--primary);
    color: white;

    &:hover {
      background: var(--primary-highlight);
    }
  }

  &:hover {
    background: #eee;
  }

  .deal-box-content {
    display: flex;
    justify-content: space-between;
  }

  .deal-box-progress-bar {
    border-radius: 0 0 $radius $radius;
    overflow: hidden;
    height: 5px;
    width: 100%;

    &::-webkit-progress-value {
      background: var(--blue);
    }

    &::-webkit-progress-bar {
      background: var(--gray-80);
    }
  }
}
.stage-column {
  max-width: 200px;
  min-width: 200px;
}
