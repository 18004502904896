.text-color-blueLight {
  color: var(--ms-fontColor-bluelight) !important;
}

.text-color-blue {
  color: var(--blue) !important;
}

.text-color-red {
  color: var(--ludvig-red-text) !important;
}

.text-color-yellow {
  color: var(--yellow) !important;
}

.text-color-green-light {
  color: var(--greenLight) !important;
}

.pog-link {
  color: var(--blue);
}

.pog-link:hover {
  color: var(--lightblue);
}

.text-grayed {
  color: var(--gray-120) !important;
}

.text-muted {
  color: var(--gray-130);
}

.danger-text {
  color: var(--danger);
}

.teal-color {
  color: var(--greenLight);
}

.hover-light-text:hover {
  color: var(--gray-700) !important;
}

.text-color-purple {
  color: var(--purple) !important;
}

.text-color-primary {
  color: var(--primary) !important;
}

.text-color-gray-700 {
  color: var(--gray-700);
}

.text-color-gray-100 {
  color: var(--gray-100);
}

.border-color-gray-700 {
  border-color: 1px solid var(--gray-700) !important;
}

.text-color-white {
  color: var(--white);
}

.text-color-yellow-dark {
  color: var(--yellowDark);
}

.background-450 {
  background: var(--gray-450);
}

.background-500 {
  background: var(--gray-500);
}
