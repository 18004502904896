.counter {
  width: 3rem;
  margin-top: 3em;
  &-bo {
    margin-top: 2em;
  }
  &-number {
    border-left: 1px solid var(--gray-30);
  }
}
.potential-deals-row:last-child {
  border-bottom: 0px solid white;
}
@media (max-width: 1540px) {
  .counter-bo {
    margin-top: 2.5em;
  }
}
