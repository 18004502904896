@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in {
  animation-name: fadeIn;
  animation-duration: 0.2s;
}

.on-hover-fade-in {
  opacity: 0;
  transition: 0.2s opacity;

  &:hover {
    opacity: 1;
  }
}

.on-hover-fade-in-50-100 {
  opacity: 0.5;
  transition: 0.2s opacity;

  &:hover {
    opacity: 1;
  }
}
