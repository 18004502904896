.overlay-drawer-extra-large {
  width: 60vw !important;
}

.overlay-drawer-extra-extra-large {
  width: 80vw !important;
}

@media (max-width: 1600px) {
  .overlay-drawer-extra-extra-large {
    width: 100vw !important;
  }
}
