$size: 18px;

.i18n-flag {
  height: $size;
  width: $size;
  object-fit: cover;
  border-radius: $size;
  cursor: pointer;
  transition: .2s filter;

  &:not(.selected) {
    filter: grayscale(1) contrast(0.5) brightness(1.5);
  }
}
