mgt-person {
  --font-family: "Segoe UI";
  --font-size: 12px;
  --font-weight: 500;
}

.persona {
  width: var(--avatar-size);
  height: var(--avatar-size);
  background: #eee;
  border-radius: 50%;
}

.persona-default-size {
  --person-avatar-size: 32px;
}

.person {
  @extend .persona-default-size;
  height: inherit;
  width: inherit;

  & > div[slot="no-data"] {
    height: 100%;
    width: 100%;
  }
}

.persona-not-active {
  position: relative;
  min-width: 215px;

  .not-active-icon {
    position: absolute;
    left: 4%;
  }

  .not-active-placeholder {
    position: absolute;
    left: 20%;
  }
}
