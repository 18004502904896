.fluent-ui-v9-switch {
  &.no-left-margin {
    & > div {
      margin-left: 0;
    }
  }
  & label {
    font-size: 13px;
    padding: var(--whitespaceExtrasmall)
  }
  & .small  {
    zoom: 0.8;
  }
}
