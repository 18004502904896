* {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    width: 10px;
    background: var(--gray-500);
    border-radius: 3px;
  }
}
