.layout {
  height: 100%;

  display: grid;
  grid-template-rows: var(--header-height) auto 1fr auto;
  grid-template-columns: auto 1fr;

  grid-template-areas:
    "layoutSidebar layoutHeader"
    "layoutSidebar layoutSubHeader"
    "layoutSidebar layoutBody"
    "layoutSidebar layoutFooter";

  overflow: hidden;

  background-color: var(--gray-400);
}

.layoutHeader {
  grid-area: layoutHeader;

  padding-top: var(--whitespaceMedium);
  padding-bottom: var(--whitespaceMedium);
  padding-left: var(--whitespaceLarge);
  padding-right: var(--whitespaceLarge);

  border-bottom: 1px solid var(--gray-400);
  box-shadow: var(--box-shadow-xs);

  background-color: var(--gray-300);

  display: flex;
  align-items: center;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;

    margin-bottom: 0;
  }

  .layoutHeader--left {
    grid-area: layoutSubHeader;
    display: flex;
  }

  .layoutHeader--right {
    margin-left: auto;
  }
}

.layoutSubHeader {
  grid-area: layoutSubHeader;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-left: var(--whitespaceLarge);
  padding-right: var(--whitespaceLarge);

  background-color: var(--gray-300);
  box-shadow: var(--box-shadow-md);

  border-radius: 0 0 24px 24px;
  border-bottom: 1px solid var(--gray-400);

  > .layoutSubHeader--topShadow {
    box-shadow: 3px 3px 15px 3px rgba(0, 0, 0, 0.08);
    width: 100%;
  }
}

.layoutSidebar {
  grid-area: layoutSidebar;
  position: relative;

  overflow-y: auto;
  overflow-x: hidden;
}

.layoutBody {
  background-color: var(--white);
  border: var(--gray-80) !important;
  border-radius: 4px;
  margin: var(--whitespaceLarge);
  padding: var(--whitespaceLarge);
}

.layoutBodyGray {
  background-color: var(--gray-300);
}

.layoutBody,
.layoutBodyGray {
  grid-area: layoutBody;
  // Normalize bottom padding and let it handled by ContentBody or ContentFooter instead.
  // For some reason there is a css bug when you have flexbox set to height 100% and flexed
  // children. This causes the end of the flexbox to ignore the padding-bottom of its parent.
  padding-bottom: 0;
  overflow: auto;
  box-shadow: var(--box-shadow-md);

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.layoutFooter {
  grid-area: layoutFooter;

  width: 100%;
  max-width: 100%;
  min-height: var(--footer-height);

  background-color: var(--gray-300);
  border-top: 1px solid var(--gray-450);
  box-shadow: var(--box-shadow-xs);

  padding-top: 12px;
  padding-bottom: 12px;

  &:hover {
    background: var(--white);
    cursor: pointer;
  }

  .footer {
    .title {
      font-weight: 700;
      font-size: 16px;
      color: var(--gray-190);
    }

    .footer-vertical-divider {
      border-left: 1px solid var(--gray-600);
      height: 5% !important;
    }

    .support-label {
      color: var(--gray-650);
    }

    .support-value {
      color: var(--gray-900);
      font-weight: 500;
    }
  }
}

.reload-vertical-divider {
  border-left: 2px solid var(--gray-600);
  height: 20px !important;
}
