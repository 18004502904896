.overlay {
  padding: 1em;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
  left: 0;
  z-index: 999999;
  background-color: rgba(255, 255, 255, 0.95);
}
