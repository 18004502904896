.change-user-in-management-modal {
  font-size: 14px;
}

.change-user-in-management-menu-btn svg {
  vertical-align: baseline;
}

.assignee-pickers-body {
  display: flex;
  margin-top: var(--whitespaceLarge);

  & > div:first-child,
  & > div:nth-child(3) {
    width: 45%;
  }

  & > div:nth-child(2) {
    width: 10%;
    text-align: center;
    margin-top: 32px;
  }
}

.project-sales-picker {
  @media (min-width: 1024px) {
    padding: 0 100px;
  }

  .person-picker-wrapper {
    min-width: 260px;
  }
}

.customer-management-list {
  overflow-x: auto;

  th,
  td {
    font-size: 14px;
  }

  .header-cell {
    font-weight: 600;
  }

  .row-selection-cell:hover {
    cursor: pointer;
  }

  .managers-cell-th {
    div {
      justify-content: right;
    }
  }

  .managers-cell-td {
    text-align: right;
  }
}

.top-sticky-header {
  position: sticky;
  top: calc(var(--whitespaceLarge) * (-1));
  z-index: 1000;
  background-color: white !important;
}

.chosen-client-program-ddl {
  > button {
    padding: 0;
  }

  &.chosen-client-program-ddl-empty {
    > button {
      color: var(--gray-900);
      font-weight: 600;
    }
  }
}

.no-longer-employee-warning {
  position: absolute;
  right: -30px;
  z-index: 1;
}
