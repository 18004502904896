.deals-filters-dropdown {
  border: 1px solid var(--gray-110) !important;
  background-color: var(--white) !important;
  // overwrite the default value of 250px for min-with from fUI
  min-width: 100% !important;
}

.deals-filter-vertical-divider {
  border-left: 1px solid var(--gray-450);
  height: 100%;
  position: inherit;
}