$border-style: 1px solid var(--gray-470);

%dateRangeDisplayShared {
  display: flex;
}

.date-range {
  @extend %dateRangeDisplayShared;
  flex-direction: column;
  border: $border-style;
  border-radius: 4px;
  padding: 8px 0 20px;
  width: fit-content;

  .date-range-header {
    @extend %dateRangeDisplayShared;
    justify-content: space-between;
    align-items: center;
    padding: 6px 20px;
    border-bottom: $border-style;
  }

  .date-range-body {
    display: grid;
    column-gap: 27px;
    grid-template-columns: repeat(2, 1fr);
    padding: 4px 20px 0;
  }
}

