.filters-list-dropdown {
  background: var(--white);
  height: min-content;
  position: fixed;
  top: 40px;
  right: 390px;
  z-index: 999;
  box-shadow: var(--box-shadow-md);
}

.filter-item-label {
  background: var(--white);
  box-shadow: var(--box-shadow-md);
  border-radius: 20px;
  padding: var(--whitespaceMegaSmall);
}

.pills-left {
  .icon {
    position: relative;
    top: 2px;
    left: 5px;
  }
  span:first-child {
    transform: translateX(2px);
    position: relative;
    bottom: 1px;
  }
}

.pills-right {
  span:first-child {
    position: relative;
    bottom: 1px;
  }
}

.filters-list-dropdown {
  .icon {
    position: relative;
    top: 3px;
    left: 5px;
  }
}

.filter-item-label-max-width {
  max-width: 120px;
}

.reset-all-button {
  color: var(--blue) !important;
}
@media (min-width: 1701px) {
  .reset-all-button {
    flex: none;
  }
}
@media (max-width: 1300px) {
  .reset-all-button {
    flex-direction: column;
  }
}
