.table-kyc {
  th:first-child,
  td:first-child {
    width: 70px !important;
    max-width: 70px !important;
  }

  th{
    white-space: break-spaces;
  }
  
  &-header-item{
    &-customerName{
      width: 20%;
    }

    &-customerNumber{
      width: 15%;
    }

    &-customerManager{
      width: 20%;
    }

    &-scoreSummary{
      width: 10%;
    }
  }
}
