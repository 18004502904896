.btn {
  align-self: unset !important;
  border-radius: 4px;
}

.btn-outline-secondary {
  color: var(--gray-160);
  border-color: var(--gray-500);

  &:hover {
    border-color: var(--gray-500);
    background-color: var(--gray-500);
  }
}