:root {
  .service-details-header {
    --person-details-wrapper-width: 100%;
    .horizontal-scroll-auto {
      overflow-y: hidden;
    }
    &-input {
      --border-color: var(--gray-450);
    }
    .year-end-month-required > div > div{
      background-color: var(--yellowLightest);
    }
  }
}
