.paginator-dropdown {
  border: none !important;
  background-color: transparent !important;
  button span {
    color: var(--blue) !important;
    font-weight: 600;
  }

  &:active {
    border: none !important;
    outline: none !important;
  }

  &:focus {
    border: none !important;
    outline: none !important;
  }

  &:visited {
    border: none !important;
    outline: none !important;
  }
}
