//Ludvig brand colors
$ludvigPrimary: #122945 !default;
$ludvigPrimaryLight: #37435e !default;
$ludvigPrimaryLightest: #e9ebef !default;

// Mittludvig colors
$blue: #0078d4 !default;
$blueLight: #2484ce !default;
$blueLightest: #f6fcff !default;
$blueLightestDarken2: darken($blueLightest, 2%) !default;
$blueLightestDarken4: darken($blueLightest, 4%) !default;
$listLinkColor: #085690 !default;

$green: #008272 !default;
$greenLight: #00b294 !default;
$greenLightest: #f2fffc !default;

$red: #c4314b !default;
$redLight: #ed5e62 !default;
$redLightest: #ffeded !default;

$yellow: #ffb900 !default;
$yellowDark: #D29200 !default;
$yellowLight: #ffb900 !default;
$yellowLightest: #fbf6d9 !default;

$gridCurrent: #eff2f5 !default;
$gridLinkColor: #1282b2 !default;

$gray-900: #242424 !default;
$gray-800: #424242 !default;
$gray-700: #616161 !default;
$gray-650: #858585 !default;
$gray-600: #c7c7c7 !default;
$gray-500: #d1d1d1 !default;
$gray-470: #e3e3e3 !default;
$gray-450: #eeeeee !default;
$gray-400: #f5f5f5 !default;
$gray-300: #f9f9f9 !default;

// Teams brand colors
$teamsBrandBg-200: #3d3e78 !default;
$teamsBrandBg-100: #444791 !default;
$teamsBrandBtn: #5b5fc7 !default;
$teamsBrandBorder: #9299f7 !default;
$teamsBrandBgLight: #e8ebfa !default;

// Base colors
$white: #fff !default;
$light: #f1f1f1 !default;

// THE END GOAL IS ALL COLOURS UNDER THIS LINE TO BE REPLACED / DELETED

// From https://github.com/OfficeDev/office-ui-fabric-core/blob/master/src/sass/variables/_Color.Variables.scss
$themeDark: #095a97 !default;
$themeFill: #f6fcff !default;

$ms-color-black: #000000 !default;
$ms-color-gray220: #11100f !default;
$ms-color-gray210: #161514 !default;
$ms-color-gray200: #1b1a19 !default;
$ms-color-gray190: #201f1e !default;
$ms-color-gray180: #252423 !default;
$ms-color-gray170: #292827 !default;
$ms-color-gray160: #323130 !default;
$ms-color-gray150: #3b3a39 !default;
$ms-color-gray140: #484644 !default;
$ms-color-gray130: #605e5c !default;
$ms-color-gray120: #797775 !default;
$ms-color-gray110: #8a8886 !default;
$ms-color-gray100: #979593 !default;
$ms-color-gray90: #a19f9d !default;
$ms-color-gray80: #b3b0ad !default;
$ms-color-gray70: #bebbb8 !default;
$ms-color-gray60: #c8c6c4 !default;
$ms-color-gray50: #d2d0ce !default;
$ms-color-gray40: #e1dfdd !default;
$ms-color-gray30: #edebe9 !default;
$ms-color-gray20: #f3f2f1 !default;
$ms-color-gray10: #f6f6f6 !default;
$ms-color-gray5: #f9f9f9 !default;
$ms-color-gray1: #fefefe !default;
$ms-color-white: #ffffff !default;

$ms-color-sharedCyanBlue10: #0078d4 !default;
$ms-color-teams-bg-light: #f9f8f8 !default;

$black: #000 !default;
$lightblue: #4472c4 !default;
$purple: #881798 !default;
$orange: #ffaa44 !default;
$teal: #00ad56 !default;

$primary: $ludvigPrimary !default;
$secondary: $yellow !default;
$success: #d4ffd4 !default;
$info: #d5f0f7 !default;
$warning: #fff4ce !default;
$danger: #d13438 !default;
$dark: $ms-color-gray80 !default;

$headline-one: 72px !default;

$enable-shadows: false !default;

$box-shadow-sm: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132),
  0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108) !default;
$box-shadow: 0 3.2px 7.2px 0 rgba(0, 0, 0, 0.132),
  0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108) !default;
$box-shadow-lg: rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px,
  rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px;
$box-shadow-md: 0px 1.2px 5px rgba(0, 0, 0, 0.1),
  0px 6.4px 20px rgba(0, 0, 0, 0.1) !default;

$box-shadow-xs: 0px 0px 25px 2px rgba(0, 0, 0, 0.06) !default;

$border-radius: 0 !default;
$border-radius-lg: 0 !default;
$border-radius-sm: 0 !default;

// Modal
$modal-inner-padding: 0;

// Button
$btn-padding-y: 0.35em;
$btn-padding-x: 1.25em;
$btn-font-weight: 600;
$btn-font-size: 14px;
$btn-font-size-sm: 10px;

$input-btn-focus-width: 0;

$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 640px,
  lg: 1024px,
  xl: 1366px,
) !default;

$container-max-widths: (
  sm: 479px,
  md: 639px,
  lg: 1023px,
  xl: 1365px,
) !default;

$font-family-sans-serif: "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$enable-responsive-font-sizes: true !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$font-weight-lighter: 400 !default;
$font-weight-light: 400 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 600 !default;
$font-weight-bolder: 700 !default;

$h1-font-size: $font-size-base * 2.625 !default;

$sidebar-width: 240px !default;
$sidebar-width-collapsed: 75px !default;
$header-height: 56px !default;
$footer-height: 56px !default;
$footer-height-expanded: 160px;

$whitespaceExtraExtralarge: 48px !default;
$whitespaceExtralarge: 36px !default;
$whitespaceLarge: 24px !default;
$whitespaceMedium: 16px !default;
$whitespace12: 12px !default;
$whitespaceSmall: 8px !default;
$whitespaceExtrasmall: 4px !default;
$whitespaceMegaSmall: 2px !default;

:root {
  --themeDark: #{$themeDark};
  --themeFill: #{$themeFill};
  --listLinkColor: #{$listLinkColor};

  --gray-900: #{$gray-900};
  --gray-800: #{$gray-800};
  --gray-700: #{$gray-700};
  --gray-650: #{$gray-650};
  --gray-600: #{$gray-600};
  --gray-500: #{$gray-500};
  --gray-470: #{$gray-470};
  --gray-450: #{$gray-450};
  --gray-400: #{$gray-400};
  --gray-300: #{$gray-300};

  --ms-color-sharedCyanBlue10: #{$ms-color-sharedCyanBlue10};
  --ms-color-teams-bg-light: #{$ms-color-teams-bg-light};

  --breadcrumb-hover-background: #{$ms-color-gray20};
  --menu-background: #f0f0f0;

  --ludvig-font-family: #{$font-family-sans-serif};

  --background: #f2f2f2;
  --background-green: #cfe9d9;

  --text-light: #fff;
  --text-dark: #191d1e;

  --page-padding: 15px;
  --page-content-width: 1344px;
  --page-content-width-breakpoint: 1080px;
  --page-header-height: 70px;
  --page-grid-gap: 25px;

  --box-background: #fff;

  --white: #{$white};
  --gray-1: #{$ms-color-gray1};
  --gray-5: #{$ms-color-gray5};
  --gray-10: #{$ms-color-gray10};
  --gray-20: #{$ms-color-gray20};
  --gray-30: #{$ms-color-gray30};
  --gray-40: #{$ms-color-gray40};
  --gray-50: #{$ms-color-gray50};
  --gray-60: #{$ms-color-gray60};
  --gray-80: #{$ms-color-gray80};
  --gray-100: #{$ms-color-gray100};
  --gray-110: #{$ms-color-gray110};
  --gray-120: #{$ms-color-gray120};
  --gray-130: #{$ms-color-gray130};
  --gray-160: #{$ms-color-gray160};
  --gray-190: #{$ms-color-gray190};
  --orange: #{$orange};
  --yellow: #{$yellow};
  --yellowDark: #{$yellowDark};
  --yellowLightest: #{$yellowLightest};
  --green: #{$green};
  --greenLight: #{$greenLight};
  --greenLightest: #{$greenLightest};
  --green-highlight: var(--ludvig-green-highlight);
  --blue: #{$blue};
  --blueLight: #{$blueLight};
  --blueLightest: #{$blueLightest};
  --blueLightestDarken2: #{$blueLightestDarken2};
  --blueLightestDarken4: #{$blueLightestDarken4};
  --teal: #{$teal};
  --purple: #{$teamsBrandBtn};
  --red: #{$red};
  --redLight: #{$redLight};
  --redLightest: #{$redLightest};

  --primary: #{$ludvigPrimary};
  --primaryLight: #{$ludvigPrimaryLight};
  --primary-highlight: #{$ludvigPrimaryLight};
  --primary-lightest: #{$ludvigPrimaryLightest};

  --secondary: var(--ludvig-red);
  --secondary-faded: var(--ludvig-red-faded);

  --ludvig-red-text: #{$redLight};
  --ludvig-red: #{$redLight};

  --ludvig-green-text: #{$greenLight};
  --ludvig-green: #{$greenLight};

  --ludvig-yellow: #{$yellowLight};

  --headline-one: #{$headline-one};

  --border-color: var(--gray-130);

  --gridCurrent: #{$gridCurrent};
  --gridLinkColor: #{$gridLinkColor};

  --box-shadow-xs: #{$box-shadow-xs};
  --box-shadow-sm: #{$box-shadow-sm};
  --box-shadow: #{$box-shadow};
  --box-shadow-lg: #{$box-shadow-lg};
  --box-shadow-md: #{$box-shadow-md};

  --tooltip-text-color: black;
  --tooltip-background-color: white;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;

  --teamsBrandBg-200: #{$teamsBrandBg-200};
  --teamsBrandBg-100: #{$teamsBrandBg-100};
  --teamsBrandBtn: #{$teamsBrandBtn};
  --teamsBrandBorder: #{$teamsBrandBorder};
  --teamsBrandBgLight: #{$teamsBrandBgLight};

  --avatar-size: 32px;

  --input-disabled-color: #777;
  --input-invalid-background: #{$redLightest};
  --input-valid-background: rgb(223, 246, 221);

  --sidebar-width: #{$sidebar-width};
  --sidebar-width-collapsed: #{$sidebar-width-collapsed};

  --naturalTertiary: #{$ms-color-gray90};

  --header-height: #{$header-height};
  --footer-height: #{$footer-height};
  --footer-height-expanded: #{$footer-height-expanded};

  --whitespaceExtraExtralarge: #{$whitespaceExtraExtralarge};
  --whitespaceExtralarge: #{$whitespaceExtralarge};
  --whitespaceLarge: #{$whitespaceLarge};
  --whitespaceMedium: #{$whitespaceMedium};
  --whitespace12: #{$whitespace12};
  --whitespaceSmall: #{$whitespaceSmall};
  --whitespaceExtrasmall: #{$whitespaceExtrasmall};
  --whitespaceMegaSmall: #{$whitespaceMegaSmall};
}
