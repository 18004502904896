.year-chart {
  fill: var(--white);

  .segment {
    cursor: pointer;
    stroke: none;

    &.outer {
      &:hover {
        fill: var(--primary-highlight) !important;
      }
    }

    &.selected {
      fill: var(--primary) !important;
    }

    &.inner {
      fill: var(--white);
    }
  }

  .text {
    pointer-events: none;
    fill: #bbb;
    font-size: 12px;
  }
}
