.bg-currentMonthColumn {
  background: var(--gridCurrent);
}

.bg-monthColumn {
  background: var(--gray-300);
  border-radius: 2px;
}

.divider-border {
  height: 2px;
  background: var(--gray-500);
}

$radius: 3px;

.service-box-progress-bar {
  border-radius: 0 0 $radius $radius;
  overflow: hidden;
  height: 6px;
  width: 100%;
  bottom: 0;
  position: relative;
  bottom: 0;

  &::-webkit-progress-value {
    background: var(--primary);
  }

  &::-webkit-progress-bar {
    background: var(--gray-500);
  }
}

.min-incomplete-services-list-height {
  min-height: 130px;

  &.shrunk {
    min-height: 80px;
  }
}

.min-incomplete-services-list-height-alt {
  min-height: 164px;

  &.shrunk {
    min-height: 80px;
  }
}

.min-services-list-height {
  min-height: 172px;

  &.shrunk {
    min-height: 133px;
  }
}

.min-services-list-height-alt {
  min-height: 160px;

  &.shrunk {
    min-height: 80px;
  }
}

.min-completed-services-list-height {
  min-height: 85px;
}

.no-completed-services-list-height {
  min-height: 42px;
}

.one-completed-service-list-height {
  min-height: 42px !important;
}
