.ghost-button { 
    display: inline-block; 
    padding: 8px;  
    border: none;
    text-align: center; 
    outline: none; 
    text-decoration: none; 
  }
  
  .ghost-button:hover {
    background-color: transparent;
  }