.heading {
  background-color: var(--gray-20) !important;
  border-left: 5px solid var(--white);
  border-bottom: transparent;
}

.price-column {
  min-width: 470px;
}

.suggested-price-column {
  min-width: 170px;
  max-width: 200px;
}

.discount-column {
  min-width: 250px;
}

.final-price-column {
  min-width: 130px;
  max-width: 180px;
}

.min-tr-height {
  min-height: 47px;
}

.min-tr-height-lg {
  min-height: 57px;
}

.not-available {
  color: var(--gray-650);
  font-style: italic;
}
