html,
body,
#root,
.app {
  margin: 0;
  width: 100%;
  height: 100%;
}

.main {
  background: var(--white);
  width: calc(100% - var(--sidebar-width));
  position: relative;
  margin-left: var(--sidebar-width);
}

@import "./global/colors.scss";

@import "./variables";
@import "./fluent";
@import "./global/utility";
@import "./global/buttons";

@import "bootstrap/scss/_mixins";
@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/_forms";
@import "bootstrap/scss/_utilities";
@import "bootstrap/scss/utilities/_api";
@import "bootstrap/scss/_reboot";
@import "bootstrap/scss/_root";
@import "bootstrap/scss/_tables";
@import "bootstrap/scss/_spinners";
@import "bootstrap/scss/_buttons";
@import "bootstrap/scss/_grid";
@import "bootstrap/scss/_card";
@import "bootstrap/scss/_transitions";
@import "bootstrap/scss/_accordion";

@import "./global/scroll";
@import "./global/table";
@import "./global/typography";
@import "./global/form";
@import "./global/animation";
@import "./global/spacing";
