.box {
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  max-width: 26vw;
  padding: var(--whitespaceExtraExtralarge) var(--whitespaceExtralarge);

  border: 1px solid var(--gray-450);

  hr {
    color: var(--gray-450);
    width: 75%;
  }

  .box-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .box-action {
    display: flex;
    align-items: center;

    cursor: pointer;

    > .box-action-title {
      color: var(--blue);
    }
    > .box-action-subtitle {
      font-style: italic;

      margin-left: var(--whitespaceMedium);
      color: var(--gray-650);
    }
  }

  &.disabled {
    opacity: 50%;

    .box-action {
      cursor: default;
    }
  }

  &.isDraggingFile {
    border: 1px solid var(--gray-500);
  }
}

.boxes {
  > .box:first-child {
    margin-left: 0;
  }

  > .box {
    margin-left: var(--whitespaceLarge);
    margin-right: var(--whitespaceLarge);
  }

  > .box:last-child {
    margin-right: 0;
  }

  hr {
    color: var(--gray-500);
    width: 75%;
  }
}
