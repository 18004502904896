.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: var(--sidebar-width);
  transition: width 0.2s ease-in-out;
  background: var(--menu-background);
  border-bottom: 1px solid var(--gray-30);
  box-shadow: inset -7px 0 9px -7px rgba(78, 78, 78, 0.1);

  &.collapsed {
    width: var(--sidebar-width-collapsed);

    .menu-group-item .menu-group-header,
    .menu-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  hr {
    opacity: 10%;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .menu-items {
    padding-top: var(--whitespaceLarge);
    padding-left: var(--whitespaceMedium);
    padding-right: var(--whitespaceMedium);
  }

  .menu-logo {
    display: flex;
    align-items: center;
    padding-top: var(--whitespaceExtrasmall);
    padding-bottom: var(--whitespaceExtrasmall);
    padding-left: var(--whitespaceMedium);
    padding-right: var(--whitespaceMedium);
    background: var(--gray-400);

    .app-title {
      color: var(--primary);
      font-size: var(--whitespaceMedium);
      font-weight: 700;
      overflow-x: hidden;
      text-wrap: nowrap;
      text-overflow: ellipsis;
      height: var(--whitespaceLarge);
    }
  }

  .menu-footer {
    padding-left: var(--whitespaceMedium);
    padding-right: var(--whitespaceMedium);

    .toggle-btn {
      display: flex;
      align-items: center;
      color: var(--primary);
      line-height: 0.4;

      .icon>i:before {
        color: var(--primary);
      }
    }
  }

  .menu-group-title {
    padding-left: 8px;
    padding-top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  .menu-group-header {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .menu-group {
    min-height: 44px;

    &.collapsed {
      i {
        transform: rotate(90deg);
      }

      ul {
        display: none;
      }

      li {
        margin-left: 0px;
      }
    }

    i {
      transition: 0.1s transform;
      font-size: 12px;
      margin-right: 0.25em;
    }

    .menu-group-header {
      font-size: 16px;
      color: var(--primary);
      border-left: 2px solid;
      border-color: var(--menu-background);
      white-space: nowrap;
    }

    .active-direction {
      flex-direction: column;
    }

    .selected {
      display: flex;
      background: var(--gray-30);
      border-radius: var(--whitespaceExtrasmall);

      .menu-group-header,
      .menu-group-item {
        color: var(--primary);
      }
    }

    .menu-group-item {
      font-size: 14px;
      border-left: 2px solid transparent;
      display: block;
      padding-left: 40px;
      width: 100%;
      color: var(--indigo);
    }
  }

  .selected-collapsed {
    width: 24px;
    height: 1px;
    margin-left: 10px;
    border-bottom: 2px solid;
    border-color: var(--darkBlue);
  }

  .selected-full {
    height: 44px;
    width: 1px;
    border-left: 2px solid;
    border-color: var(--darkBlue);
  }

  .selected-full-submenu {
    height: 37px;
    width: 1px;
    border-left: 2px solid;
    border-color: var(--darkBlue);
  }
}

.hover-menu:hover {
  opacity: 0.7;
}