.vertical-wizard-divider {
  border-left: 1px solid var(--gray-450);
  margin-left: 10px;
  margin-top: 10px;
}

.vertical-wizard-stepper {
  .stepLabel {
    color: var(--gray-700);

    &.active {
      color: var(--gray-900) !important;
    }

    &.valid {
      color: var(--gray-700);
    }

    &.stepLabel.passed {
      color: var(--gray-900) !important;
    }
  }

  .stepNumber {
    color: var(--white);
    background-color: var(--blue);
    display: inline-block;
    line-height: 0px;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 500;

    span {
      display: inline-block;
      padding-top: 50%;
      padding-bottom: 50%;
      margin-left: 8px;
      margin-right: 8px;
    }

    &.active {
      background-color: var(--blue) !important;
    }

    &.disabled {
      background-color: var(--gray-600);
    }

    &.valid {
      background-color: var(--blueLight);
    }

    &.passed {
      background-color: var(--blue) !important;
    }
  }
}
