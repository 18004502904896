.service-modal {
 .modal-content{
  padding-bottom: 0 !important;
 }
  .modal-body {
    padding: 0 !important;
  }
  .content{
    .contentFooter {
      padding-left: 0;
      padding-right: 0;
    }
  }
}