.no-activities-box {
  margin: 0;
  position: relative;
}

.activity-table-spinner {
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-style {
  text-align: center;
  font-style: italic;
  font-size: 16px;
  font-weight: 400;
  color: var(--gray-600);
}

.activity-panel-header {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;

  .sub-header {
    color: var(--gray-60);
    font-size: 14px;
    padding-top: 2px;
    font-weight: normal;
  }
}

.activity-table-header {
  padding-left: 62px;
}

.activities-table-ghost-button {
  background: "none";
  border: "none";
  padding: 0;
  cursor: "pointer";
}
