.picker-wrapper {
  position: relative;
  width: 190px;
  border: 1px solid var(--themeDark);
  border-radius: 4px;
  cursor: pointer;
  max-height: 192px;
  overflow: hidden;
  transition: 0.2s;

  &:hover {
    transform: scale(1.05);
    background: linear-gradient(-45deg,
        var(--white),
        var(--blueLightest),
        var(--white),
        var(--blueLightest));
    background-size: 300% 300%;
    animation: gradient 3s ease infinite;
  }

  &.selected {
    background-color: var(--blueLightest);
  }

  &.selected {
    max-height: 500px;
    border: 1px solid var(--blue);

    .picker-selected {
      opacity: 1;
      background-color: var(--blueLight);
    }
  }
}

.picker-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 2px;

  top: 0;
  right: 0;
  font-size: 1.25em;
  width: 1.25em;
  height: 1.25em;

  background-color: var(--themeDark);
  color: white;
  opacity: 0;
  transition: 0.05s opacity;
}

.picker {
  padding-top: 35px;
  width: 100%;
  height: 190px;

  hr {
    width: 20%;
    color: var(--gray-650);
  }

  .picker-heading {
    height: 30%;
    color: var(--themeDark);
    padding: 0 2px 0 2px;
    font-size: 18px;
  }

  .picker-description {
    color: var(--gray-650);
  }

  &.no-description {
    padding-top: 0px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap !important;
  }
}

.picker-toggles {
  width: 100%;
  border-top: 1px solid var(--blue);

  .picker-toggle:last-child {
    margin-bottom: 0;
  }

  &.selected {
    background-color: var(--blueLightest);
    border-top: none;
    border-left: 1px solid var(--blue);
    border-right: 1px solid var(--blue);
    border-bottom: 1px solid var(--blue);
  }
}

.carousel-arrow {
  color: var(--blueLight);
  cursor: pointer;
  transition: transform .2s ease-in-out;

  &.disabled {
    color: var(--gray-500);
  }

  &:hover {
    transform: scale(1.2);
  }
}