.dropFileAreaPdf-container {
  width: 75%;
  padding-left: var(--whitespaceSmall);

  &.disabled {
    opacity: 0.25;
  }

  .horizontal-divider {
    width: 15%;
    display: block !important;
  }
}
